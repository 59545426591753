import { Controller } from "@hotwired/stimulus"
import { Modal } from 'bootstrap'
import Experience from "../src/Experience.js";
import $ from 'jquery'

export default class extends Controller {
  static values = { game: Boolean }

  connect() {
    this.experience = new Experience()
    this.mouse = this.experience.mouse
    this.video = document.getElementById("game-loading-video");
    this.cameraOrigin = this.mouse.cameraOrigin
    this.mouse.moveCameraTo(this.cameraOrigin)

    const gameLoadingModalElement = document.getElementById("game-loading-modal")
    this.gameLoadingModal = Modal.getOrCreateInstance(gameLoadingModalElement)

    this.video.addEventListener("ended", (event) => {
      document.getElementById("skip-loading-video").classList.add("hidden")
      document.getElementById("watch-again-loading-video").classList.remove("hidden")
      document.getElementById("play-game-loading-video").classList.remove("hidden")

      this.video.setAttribute("videoPlayed", true)
    });

    document.querySelectorAll('#sound-toggle .sound-on').forEach((element) => {
      element.addEventListener('click', (event) => {
        this.toggleMusicOff()
      });
    });


    document.querySelectorAll('#sound-toggle .sound-off').forEach((element) => {
      element.addEventListener('click', (event) => {
        this.toggleMusicOn()
      });
    });
  }

  playVideo() {
    this.video.currentTime = 0
    this.video.play()
  }

  dismissModal() {
    this.gameLoadingModal.hide()
    this.experience.world.backgroundMusic.playMusic();
    $('.navbar-nav .hidden').removeClass('hidden');
    $('.instructions.hidden').removeClass('hidden');
  }

  toggleMusicOff() {
    this.experience.world.backgroundMusic.stopMusic();
    $('#sound-toggle .sound-on').removeClass('active')
    $('#sound-toggle .sound-on').addClass('hide')
    $('#sound-toggle .sound-off').addClass('active')
    $('#sound-toggle .sound-off').removeClass('hide')
  }

  toggleMusicOn() {
    this.experience.world.backgroundMusic.playMusic();
    $('#sound-toggle .sound-off').removeClass('active')
    $('#sound-toggle .sound-off').addClass('hide')
    $('#sound-toggle .sound-on').addClass('active')
    $('#sound-toggle .sound-on').removeClass('hide')
  }
}
